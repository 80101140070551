@import '../../variable';

/*div.body-container {
}*/

.diet-main {
  flex-grow: 1;
  padding-right: 20px;
  width: 100%;
  @media only screen and (pointer: coarse) {
    padding: unset;
  }
}
.diet-header{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.diet-main-old {
  flex-grow: 1;
  padding-right: 20px;
  width: 66%;
  @media (max-width: 1200px) {
    width: 100%;
    padding-right: 0px;
  }
}

.diet-side-old {
  flex-grow: 1;
  padding-left: 20px;
  width: 33%;
  @media (max-width: 1200px) {
    width: 1%;
    padding-left: 0px;
  }
}

.diet-side {
  flex-grow: 1;
  padding-left: 20px;
  width: 33%;
  position: fixed;
  right: 40px;
  top: 75px;
  bottom: 10px;
  @media (max-width: 1038px) {
    width: 1%;
    padding-left: 0;
  }
}
.client-diet-side > div {
  height: 100%;
  .diet-side-ch {
    overflow-y: auto;
    width: 100%;
    border-bottom-right-radius: 25px;
    //100% - (history-widget-head max-height + margin) - (wrapper bottom padding + 5px)
    height: calc(100% - 56px - 90px);
  }
}
.diet-side-ch {
  width: 100%;
  max-height: 75vh;
  max-width: 100%;
  overflow-x: hidden;
}

.diet-side-ch::-webkit-scrollbar {
  display: none;
}

.mobile-side {
  background-color: rgba(0, 0, 0, 1) !important;
  width: 500px !important;
  padding-top: 90px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: $body-mobile-large) {
    width: 100% !important;
  }

  p {
    color: #aaaaaa;
  }

  .bodytest_text {
    color: #aaaaaa;
  }
}

.mobile-side::-webkit-scrollbar {
  width: 0px;
}

.diet-widget {
  width: 100%;
}

.diet-loading-side{
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 13px 13px;
  span{
    font-size: 1.3rem;
    color: white;
    font-weight: 100;
    line-height: 30px;
  }
}

.diet-input{
  -moz-appearance: textfield;
  padding: 5px;
  border: 0px solid #888;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  font-size: 1em;
  background-color: rgba(0, 0, 0, 0.7);

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  /*&:disabled {
    //color: #555 !important;
  }*/
}

.body-input {
  -moz-appearance: textfield;
  font-family: 'Roboto', sans-serif;
  padding: 0px 0px 0px 5px;
  border: 0px solid #888;
  border-radius: 5px;
  color: white;
  width: 110px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.0);

  &:focus {
    border: 0px solid #3d7697;
    outline: none;
  }

  &:disabled {
    color: white !important;
  }
}

.btn-primary {
  font-family: 'Roboto', sans-serif;
  float: right;
  min-width: 70px;
  background: #1773a1;
  border-radius: 15px;
  border: 2px solid #5eb5e9;
  margin: 5px;
  padding: 3px 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  white-space: pre;

  &:hover {
    background: #166590;
    border-color: #4b90b9;
  }

  &:active {
    background: #13597f;
    border-color: #3d7697;
  }

  &:disabled {
    background: #808080;
    color: #f1f1f1 !important;
    border-color: #f1f1f1;
    cursor: not-allowed;
  }
}

// .diet-table {
//   border-collapse: separate;
//   border-spacing: 2px 9px;
//   font-size: 0.9em;
//   font-weight: 600;
//   background: rgba(0, 0, 0, 0.7);
//   border-radius: 10px;
//   margin: 0px 0px 20px 0px;
//   padding: 2px 5px 2px 5px;
//   overflow-x: auto;
//   &::-webkit-scrollbar{
//     display: none;
//   }
//   thead {
//     font-size: 1.1em;
//     color: rgba(255, 255, 255, 1.0);

//     tr {
//       td {
//         font-size: 1.1em;
//         color: rgba(255, 255, 255, 1.0);
//       }
//     }
//   }

//   td {
//     color: rgba(255, 255, 255, 0.65);
//   }

//   tr {
//     margin: 20px;
//   }
// }

.diet-table-desktop {
  width: 100%;
  border-top-left-radius: 0;
  border-spacing: 0 9px;
  td {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.diet-table-mobile {
  width: 100%;
  padding: 2px 0;
  border-radius: unset;
  overflow: unset;
  border-spacing: 0 9px;
  td {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.diet-wrapper {
  display: flex;
  flex-direction: column;
  flex: 70%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.diet-history{

  thead tr td{
    font-size: 1.3rem;
    color: white;
    font-weight: 100;
    line-height: 30px;
  }

  .diet-history-subheader {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);
    padding-top: 20px;
  }
}
.white_tr {
  white-space: pre;
}

/* Chrome, Safari, Edge, Opera */
.diet-quantity-input::-webkit-outer-spin-button,
.diet-quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.diet-quantity-input[type=number] {
  -moz-appearance: textfield;
}

.diet-quantity-input {
  border: 0px solid #888;
  //border-radius: 10px;
  color: rgba(255, 255, 255, 0.8);
  width: 45px;
  font-size: 1em;
  float: left;
  background-color: rgba(0, 0, 0, 0.1);

  &:focus {
    //border: 2px solid #3d7697;
    outline: none;
  }
}

//.foods-remove, .diet-remove-button {
//  outline: none;
//  border: 0;
//  background: 0 0 !important;
//  color: #ff434b !important;
//  text-shadow: 0 -1px 0 #CD0707, -1px 0 0 #CD0707, 0 1px 0 #CD0707, 1px 0 0 #CD0707 !important;
//  transition: all 0.2s ease-in;
//
//  &:hover {
//    cursor: pointer;
//    color: #bf2200 !important;
//    text-shadow: 0 -1px 0 #AA0101, -1px 0 0 #AA0101, 0 1px 0 #AA0101, 1px 0 0 #AA0101 !important;
//  }
//
//  &:active {
//    color: #9b0018 !important;
//    text-shadow: 0 -1px 0 #d10101, -1px 0 0 #d10101, 0 1px 0 #d10101, 1px 0 0 #d10101 !important;
//    outline: none;
//  }
//
//  &:disabled {
//    outline: none;
//  }
//}
//
//.food-remove {
//  height: 24px;
//  width: 24px;
//  padding: 0 5px 0 0;
//}

//.diet-remove-button {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//
//  i {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    font-size: 30px;
//    height: 28px;
//    width: 28px;
//  }
//}

.head-text {
  font-size: 1.1em;
  color: rgba(255, 255, 255, 1.0);
  font-weight: 600;
  float: left;
}

.dietdropdown-list {
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  font-weight: 600;
  padding: 2px 5px 2px 15px;
  background: rgba(0, 0, 0, 0.7);

  thead {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);

    tr {
      p {
        font-size: 1.1em;
        color: rgba(255, 255, 255, 1.0);
      }
    }
  }

  tbody {
    tr {
      td {
        a {
          color: rgba(255, 255, 255, 0.6) !important;
          border-bottom: none !important;
        }
      }
    }
  }

  /*tr {
    //padding: 20px;
  }*/

  .dietdropdown-option {
    padding: 2px 5px 2px 5px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 1.0em;
    line-height: 1.6em;
    min-width: 200px;
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, .1);
    }
  }
}

.invoices-list {
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  @media (max-width: 500px) {
    overflow-x: auto;
    display: block;
  }

  thead {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);

    tr {
      p {
        font-size: 1.1em;
        color: rgba(255, 255, 255, 1.0);
      }
    }
  }

  .invoices-option {
    color: rgba(255, 255, 255, 0.65);
    font-size: 1.0em;
    line-height: 1.6em;
    min-width: 200px;
    border: 1px solid transparent;
    width: 100%;
  }
}

.templates-table {
  border-radius: 10px;
  font-size: 0.9em;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 5px 2px 15px;

  thead {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);

    tr {
      td {
        padding: 10px 0px;
        font-size: 1.1em;
        color: rgba(255, 255, 255, 1.0);
      }
    }
  }

  tbody {
    tr {

      td {
        padding: 8px 0px;
        color: rgba(255, 255, 255, 0.65);
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, .1);
      }
    }
  }
}


.btn-plus {
  font-family: 'Roboto', sans-serif;
  background: #1773a1;
  border-radius: 15px;
  border: 2px solid #5eb5e9;
  color: #fff;
  cursor: pointer;
  user-select: none;
  outline: none;
  font-size: 0.7em;
  //transition: all 0.3s;
  &:hover {
    background: #166590;
    border-color: #4b90b9;
  }

  &:active {
    background: #13597f;
    border-color: #3d7697;
  }
}

//.foods-edit {
//  height: 24px;
//  width: 24px;
//  font-size: 16px;
//  color: rgba(63, 155, 191, 0.8);
//  background-color: rgba(0, 0, 0, 0.0);
//  border: 0px solid #000000;
//  padding: 0px 5px 0px 0px;
//  outline: none;
//
//  &:hover {
//    color: rgba(63, 155, 191, 1);
//    cursor: pointer;
//  }
//
//  &:active {
//    outline: none;
//  }
//}

.foods-block {
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 10px 50px 10px;
  border-radius: 10px;
}

.field-food-label {
  white-space: pre;
  color: #aaaaaa;
  font-weight: 600;
  font-size: 1.1em;
  padding-left: 5px;
  text-transform: lowercase;
}

.foods-inline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.foods-select {
  border: 0;
  color: rgba(255, 255, 255, 0.6) !important;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1em;
  -webkit-appearance: none !important;
  //background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  //background-color: #000000;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  margin: 8px 0px 0px 0px;
  text-transform: lowercase;

  option {
    text-transform: lowercase;
  }

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  option {
    cursor: pointer;
    background: #000000 !important;
  }

  .Select-value-icon.Select-value-icon {
    color: red;
    border: 0;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 0 5px;
    position: relative;
    top: -1px;
  }

  .Select-value.Select-value {
    color: white;
    display: inline-block;
    margin-left: 0;
    margin-top: 5px;
    vertical-align: middle;
    padding: 5px;
    border: 0;
  }
}

.body-test-select {
  height: 30px;
  border: 0;
  color: rgba(255, 255, 255, 0.6) !important;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1em;
  -webkit-appearance: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  margin: 8px 0px 0px 0px;
  text-transform: lowercase;

  option {
    text-transform: lowercase;
  }

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  option {
    cursor: pointer;
    background: #000000 !important;
  }

  .Select-control{
    min-width: unset;
  }

}

.body-test-select-label{
  margin: 10px 10px 0 5px;
  flex: 1 0 55%;
}

.body-test-select-wrapper{
  flex: 1 1 auto;
}

.foods-input {
  -moz-appearance: textfield;
  padding: 5px;
  margin: 8px 0px 0px 0px;
  border: 2px solid transparent;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.1);

  &.right {
    text-align: right;
  }

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  &:disabled {
    background-color: black;
    color: #aaaaaa !important;
  }
}

.field-error-my {
  border: 2px solid #FF0000 !important;
  background-color: #ebcccc !important;
  color: #eb4545 !important;
}

.confirm-delete {
  font-family: 'Roboto', sans-serif;
  float: right;
  min-width: 70px;
  background: rgb(240, 56, 2);
  border-radius: 15px;
  border: 2px solid rgb(240, 56, 2);
  margin: 5px;
  padding: 4px 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  white-space: pre;

  &:hover {
    background: rgb(200, 16, 0);
    //border-color: #4b90b9;
  }

  &:active {
    background: rgb(150, 16, 0);
    //border-color: #3d7697;
  }

  &:disabled {
    background: #808080;
    color: #f1f1f1 !important;
    border-color: #f1f1f1;
    cursor: not-allowed;
  }
}

.progress-table-one {
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  font-weight: 600;
  padding-top: 1px;
  margin: 0px 0px 5px 0px;

  thead {
    tr {
      td {
        height: 2.0em;
        padding-top: 8px;
        font-size: 1.1em;
        color: rgba(255, 255, 255, 1.0);
      }
    }
  }

  tbody {
    tr {
      color: rgba(255, 255, 255, 0.65);

      td {
        height: 1.5em;
        padding-left: 10px;
      }
    }
  }
}

.progress-table-two {
  text-rendering: optimizeLegibility;
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  text-align: right;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 600;
  margin: 0px 0px 5px 0px;

  thead {
    tr {
      td {
        height: 2.0em;
        padding-top: 8px;
        font-size: 1.1em;
      }
    }
  }

  tbody {
    tr {
      td {
        height: 1.5em;
      }
    }
  }
}

.btn-body {
  font-family: 'Roboto', sans-serif;
  width: 10em;
  background: #1773a1;
  border-radius: 5px;
  border: 2px solid #5eb5e9;
  margin: 15px 15px;
  padding: 3px 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  white-space: pre;
  font-weight: 600;

  &:hover {
    background: #166590;
    border-color: #4b90b9;
  }

  &:active {
    background: #13597f;
    border-color: #3d7697;
  }

  &:disabled {
    background: #808080;
    color: #f1f1f1 !important;
    border-color: #f1f1f1;
    cursor: not-allowed;
  }
}

.tab-body-active {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.1em;
  width: 24%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
  text-align: center;
  padding-bottom: 10px;
  text-transform: lowercase;
}

.tab-body {
  font-family: 'Roboto', sans-serif;
  width: 24%;
  color: #aaaaaa;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  text-align: center;
  text-transform: lowercase;
}

.body-arrow {
  //border-color: #5eb5e9;
  //background-color: #1773a1;
  width: 12%;
  cursor: pointer;
  margin-right: 15px;

  .icon-bg {
     fill: #5eb5e9;
  }
  .icon-border {
    fill: #1773a1;
  }

  /*&:hover {
    //background: #166590;
    //border-color: #4b90b9;
  }*/

  /*&:active {
    //background: #13597f;
    //border-color: #3d7697;
  }*/

  &:disabled {
    //background: #808080;
    //color: #f1f1f1 !important;
    //border-color: #f1f1f1;
    cursor: not-allowed;
  }
}

.body-arrow--disabled {
  cursor: not-allowed;

  .icon-bg {
    fill: #808080;
  }
  .icon-border {
    fill: #f1f1f1;
  }
}

.invoice-row-border {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 5px;
}

//.trainers-table {
//  border-collapse: separate;
//  border-spacing: 2px 9px;
//  font-size: 1.0em;
//  font-weight: 600;
//  color: rgba(255, 255, 255, 0.65);
//  padding: 15px 0px 20px 15px;
//  background-color: rgba(0, 0, 0, 0.7);
//  width: 100%;
//  border-radius: 10px;
//
//  thead {
//    tr {
//      td {
//        padding-bottom: 10px;
//        font-size: 1.1em;
//        color: rgba(255, 255, 255, 1.0);
//        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
//      }
//    }
//  }
//
//  tbody {
//    tr {
//      &:hover {
//        cursor: pointer;
//        background-color: rgba(255, 255, 255, .1);
//      }
//    }
//  }
//}

.manage-invoice-table {
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 1.1em;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.65);
  padding: 15px 0px 20px 0px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;

  tbody {
    td {
      &.right {
        text-align: right;
      }
    }
  }
}

.diet-daily-intake-div {
  margin: 0 5px 0 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

// .diet-tab-header{
//   display: flex;
//   flex-flow: row wrap;
//   align-items: center;
//   justify-content: center;
//   background: rgba(0, 0, 0, 0.7);
//   border-top-right-radius: 20px;
//   border-top-left-radius: 20px;
//   margin-right: 10px;
//   padding: 10px 15px;
//   color: white;
//   font-size: 1.3rem;
//   font-weight: 100;
//   line-height: 30px;
//   cursor: pointer;
//   &.firefox-right-padding{
//     @media(min--moz-device-pixel-ratio:0) {
//       padding-right:0;
//     }
//   }
// }

.meal-checkbox-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

// .diet-for-days-div{
//   width: 200px;
//   min-width: 200px;
// }

//.diet-for-days-select {
//  cursor: pointer;
//  border-radius: 10px;
//  height: 36px;
//  &.is-disabled{
//    div{
//      background-color: transparent !important;
//    }
//  }
//  .Select-menu-outer {
//    border-radius: 10px;
//    margin-top: -36px;
//    div {
//      border-radius: 10px;
//      div {
//        border-radius: 0;
//        &:first-child {
//          border-top-left-radius: 10px;
//          border-top-right-radius: 10px;
//        }
//
//        &:last-child {
//          border-bottom-left-radius: 10px;
//          border-bottom-right-radius: 10px;
//        }
//      }
//    }
//    .for-days-mobile-option{
//      font-size: 1.2rem;
//    }
//  }
//
//  &.for-days-mobile{
//    span{
//      font-size: 1.2rem;
//    }
//  }
//
//  &.for-days-desktop-menu{
//    .Select-menu-outer{
//      width: calc(100% + 55px);
//    }
//  }
//
//  &.for-days-mobile-menu{
//    .Select-menu-outer{
//      width: calc(100% + 45px);
//    }
//  }
//
//  div {
//    color: rgba(255, 255, 255, 0.6);
//    min-width: unset;
//
//    span div {
//      display: flex;
//      justify-content: center;
//
//      span {
//        color: rgba(255, 255, 255, 0.6);
//        font-size: 1.3rem;
//        font-weight: 100;
//        line-height: 30px;
//        display: flex;
//        align-items: center;
//      }
//    }
//  }
//}

.diet-main-notes {
  margin-top: 10px;
  border-bottom: 1px solid #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: auto;
}

.diet-add-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 10px 0 10px 0;
}

.diet-add-button {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  border: none;
  cursor: pointer;
  font-size: 30px;
  outline: none;
  color: #1773a1;

  &:hover {
    color: #166590;
  }

  &:disabled{
    color: #808080;
    cursor: default;
  }

  i {
    height: 28px;
    width: 28px;
  }
}

.diet-clone-icon {
  font-size: 28px;
}

// .diet-meals{
//   background: rgba(0, 0, 0, 0.7);
//   border-radius: 10px;
//   height: calc(100% - 20px);
//   margin-bottom: 20px;
//   display: flex;
//   flex-direction: column;
//   .diet-meals-header{
//     height: 56px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: auto;
//     span{
//       font-size: 1.3rem;
//       color: white;
//       font-weight: 100;
//       line-height: 30px;
//     }
//   }
// }

.diet-meals-button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
}

.cst-select {
  //font-family: 'Roboto', sans-serif !important;
  border: 0;
  //color: rgba(255, 255, 255, 0.6) !important;
  padding: 5px 0px;
  border-radius: 10px;
  //font-size: 1em !important;
  //background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  margin: 8px 0px 0px 0px;

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }
}

.cst-select-all {
  //font-family: 'Roboto', sans-serif !important;
  border: 0;
  //color: rgba(255, 255, 255, 0.6) !important;
  padding: 0px 0px;
  border-radius: 10px;
  //font-size: 1em !important;
  //background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  margin: 8px 0px 0px 0px;

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }
}

.cst-option {
  font-family: 'Roboto', sans-serif !important;
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 600;
  font-size: 1.0em;
}

.side-closer {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50px;
  right: 18px;
  cursor: pointer;
}

.toolt {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //padding-right: 150px !important;
  z-index: 1300 !important;

  .rc-slider-tooltip-body-content {
    .rc-slider-tooltip-body-inner {
      max-width: 400px;
      padding: 8px 10px;
      text-align: left;
      background-color: rgba(0, 0, 0, 0.9);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      min-height: 35px;

      span {
        color: #ffffff;
        font-size: 15px;
      }
    }
  }
}

.toolp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 60px !important;
  z-index: 1300 !important;

  .rc-slider-tooltip-content {
    .rc-slider-tooltip-inner {
      background-color: rgba(0, 0, 0, 0.9);
      max-width: 300px;
      padding-bottom: 45px;

      span {
        color: #ffffff;
        font-size: 15px;
      }
    }
  }
}

.bodyProgress-notes-tooltip-cell {
  display: table-cell;
  text-align: center;
  float: right;
  white-space: nowrap;
}

.tooltAll {
  .rc-slider-tooltip-all-content {
    .rc-slider-tooltip-all-inner {
      max-width: 400px;
      padding: 8px 10px;
      text-align: left;
      background-color: rgba(0, 0, 0, 0.9);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      min-height: 35px;

      span {
        color: #ffffff;
        font-size: 15px;
      }
    }
  }
}

.toolpAll {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //padding-right: 60px !important;
  z-index: 1300 !important;

  .rc-slider-tooltip-content {
    .rc-slider-tooltip-inner {
      background-color: rgba(0, 0, 0, 0.9);
      max-width: 300px;
      padding-bottom: 45px;

      span {
        color: #ffffff;
        font-size: 15px;
      }
    }
  }
}

.paging-label {
  padding: 0px 5px;
  margin-top: 6px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6)
}

.btn-primary-fixed {
  font-family: 'Roboto', sans-serif;
  min-width: 100px;
  background: #1773a1;
  border-radius: 15px;
  border: 2px solid #5eb5e9;
  margin: 5px;
  padding: 3px 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  white-space: pre;

  &:hover {
    background: #166590;
    border-color: #4b90b9;
  }

  &:active {
    background: #13597f;
    border-color: #3d7697;
  }

  &:disabled {
    background: #808080;
    color: #f1f1f1 !important;
    border-color: #f1f1f1;
    cursor: not-allowed;
  }
}

.diet-meals-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  color: white;
}

.diet-table-column-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}


//Recomposer python list styles:
.table {
  width: 100%;
}

.table__delete-cell {
  width: 4%;
  @media screen and (max-width : 480px) {
      width: 25%;
  }
}

.table__name-cell {
 width: auto;
}

.table__name-cell-container {
  display: flex;
}

.table__edit-icon {
  padding-top: 1px;
}

.table__link {
  color: rgba(255,255,255, 0.6);
  border-bottom: none;
}

.table__rigth-cell {
  width: 20%; /*Частный случай. Вынести*/
  min-width: 100px;
}
