@import './../../../breakpoints.scss';

.payment-page__widget {
  overflow-x: auto;
}

.payment-page__body-main {
  padding-right: 10px;
  width: 66%;

  @include _1200 {
    padding: 0;
  }

  @include _480 {
    margin: 0;
    padding: 0;
  }

  @include _1200 {
    width: 100%;
  }
}

.payment-page__content {
  position: relative;
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-grow: 1;
  padding: 0;
  @include _480 {
    margin: 0;
    padding: 0;
  }
  table {
    padding: 0;
  }
  thead {
    @include _480 {
      font-size: 16px;
    }
    @include _428 {
      font-size: 14px;
    }
    @include _375 {
      font-size: 12px;
    }
  }
}

.payment-page__body-side {
  flex-grow: 1;
  padding-left: 40px;
  width: 33%;
  position: fixed;
  right: 48px;
  top: 75px;
  bottom: 10px;
  @include _1200 {
    width: 1%;
    padding-left: 0;
  }
  .widget-body {
    min-height: 80vh;
  }
}

@media (max-width: 600px){
  .payment-page{
    padding: 0;
  }
}
