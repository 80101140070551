.pay-current-due {
  display: flex;
  margin: 40px 0 30px;
  justify-content: space-between;
  &__msg-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  &__msg {
    white-space: pre;
    color: #aaaaaa;
    font-weight: 600;
    font-size: 1.1em;
    padding-left: 5px;
    text-transform: lowercase;
    margin: 0 0 0 5px;
    &_summ {
      background: #1a1a1a;
      border-radius: 15px;
      width: fit-content;
      padding: 0 35px;
      line-height: 28px;
      margin: 0 0 0 5px;
    }
  }
  &__button {
    height: fit-content;
    align-self: end;
    margin: 0 0 0px;
    justify-self: end;
  }
}
