.credit-card-form {
  &--hidden {
    display: none;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 5px;

    &:first-child {
      margin-top: unset;
    }
  }

  &__input {
    width: 30%;
    height: 50px;
    margin-top: unset;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);

    &--single {
      width: 60%;
    }

    &--error {
      color: red;
    }
  }

  &__label {
    padding-right: 5px;
  }

  &__checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    margin: 15px 5px;
  }

  &__checkbox-label {
    padding-left: 10px;
    text-transform: unset;
    white-space: normal;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__footer-status-unverified {
    margin: auto auto auto 5px;
    color:#f29d09;
  }

  &__footer-status-verified {
    color:rgb(29, 166, 47);
  }
}
