.client-body {
  &__head-container {
    padding: 0px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    margin: 0;
    color: #f0f8ff;
    font-size: 1em;
  }

  &__body-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 15px;

    &_inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 35px;
      max-width: 100%;
      overflow-x: hidden;
      padding: 5px 25px 5px  15%;
    }
  }
}

