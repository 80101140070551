@import './../../breakpoints';

.activity {
  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-grow: 1;
    padding: 0;
    padding: 6.5px 0 8px;
    @include _480 {
      margin: 0;
      padding: 0;
    }
    table {
      padding: 0;
    }
    thead {
      @include _480 {
        font-size: 16px;
      }
      @include _428 {
        font-size: 14px;
      }
      @include _375 {
        font-size: 12px;
      }
    }
  }

}

.activityDropdown-list {
  border-radius: 10px;
  font-size: 0.93em;
  font-weight: 600;
  thead {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);
    max-width: 100%;
    overflow-x: hidden;
  }
}
